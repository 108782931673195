<template>
  <b-card>
    <b-list-group>
      <b-list-group-item
        v-for="(data, index) in limitData(dataSubMenu)"
        :key="index"
        :active="data.name == currentRouteName || data.name == dataMenu"
        class="d-flex justify-content-between align-items-center"
        :href="data.path"
      >
        {{ data.meta.pageTitle }}
        <span
          v-if="data.notes != '' && notifData[data.notes] !== 0 && userData.group_id !== 10"
          class="badge rounded-pill bg-danger ms-50"
        >
          {{ notifData[data.notes] }}
        </span>
      </b-list-group-item>
    </b-list-group>

    <template v-if="userData.group_id !== 10">
      <h4 class="text-center mt-2">
        Master Data Referensi
      </h4>
      <b-list-group>
        <b-list-group-item
          v-for="(data, index) in dataSubMaster"
          :key="index"
          :active="data.name == currentRouteName || data.name == dataMenu"
          class="d-flex justify-content-start align-items-center"
          :href="data.path"
        >
          {{ data.meta.pageTitle }}
        </b-list-group-item>
      </b-list-group>
    </template>

    <h4 class="text-center mt-2">
      Efisiensi Anggaran
    </h4>
    <b-list-group>
      <b-list-group-item
        v-if="userData.group_id === 100"
        href="/pengajuan/efisiensi"
      >
        Form Efisiensi Anggaran
      </b-list-group-item>
    </b-list-group>

    <h4 class="text-center mt-2">
      Panduan
    </h4>
    <b-list-group>
      <b-list-group-item v-if="userData.group_id === 100">
        <b-link
          href="/panduan/manual_biro_perencanaan.pdf"
          target="_blank"
        >
          Panduan Biro Perencanaan 2023
        </b-link>
      </b-list-group-item>
      <b-list-group-item v-if="userData.group_id === 100">
        <b-link
          href="/panduan/manual_biro_perencanaan_2024.pdf"
          target="_blank"
        >
          Panduan Biro Perencanaan 2024
        </b-link>
      </b-list-group-item>
      <b-list-group-item v-else>
        <b-link
          href="/panduan/manual_satuan_kerja.pdf"
          target="_blank"
        >
          Panduan Satuan Kerja
        </b-link>
      </b-list-group-item>
    </b-list-group>

    <h4 class="text-center mt-2">
      Contoh Form Daftar Data
    </h4>
    <b-list-group>
      <b-list-group-item>
        <b-link
          href="/panduan/form_daftar_data_spasial_clearance_spba_ta_2024.xlsx"
          target="_blank"
        >
          Form Daftar Data Spasial
        </b-link>
      </b-list-group-item>
      <b-list-group-item>
        <b-link
          href="/panduan/form_daftar_data_statistik_clearance_spba_ta_2024.xlsx"
          target="_blank"
        >
          Form Daftar Data Statistik
        </b-link>
      </b-list-group-item>
      <b-list-group-item>
        <b-link
          href="/panduan/form_daftar_data_lainnya_clearance_spba_ta_2024.xlsx"
          target="_blank"
        >
          Form Daftar Data Lainnya
        </b-link>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import { getUserData } from '@/auth/utils'
import {
  BLink, BCard, BListGroup, BListGroupItem,
} from 'bootstrap-vue'

import subMenu from './SubMenuPengajuan'
import subMaster from './SubMenuMaster'

export default {
  components: {
    BLink,
    BCard,
    BListGroup,
    BListGroupItem,
  },
  props: {
    dataMenu: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      userData: getUserData(),
      dataSubMenu: subMenu,
      dataSubMaster: subMaster,
      notifData: {},
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
  mounted() {
    const role = Number(localStorage.getItem('roleId'))
    if (role > 10) {
      this.$http.get('/notification/get-ippd-notification', {
        params: {
          token: localStorage.getItem('userToken'),
          tahun_anggaran: localStorage.getItem('tahunAnggaran'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.notifData = res.data.data
          }
        })
    }
  },
  methods: {
    limitData(arr) {
      const year = localStorage.getItem('tahunAnggaran')
      const limited = []

      if (this.userData.group_id === 10) {
        arr.map(menu => {
          if (menu.admin === true && menu.year > 2023) {
            limited.push(menu)
          }
          return true
        })
      } else {
        arr.map(menu => {
          if (year >= menu.year) {
            limited.push(menu)
          }
          return true
        })
      }
      return limited
    },
  },
}
</script>
